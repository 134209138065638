<template>
  <div>
    <big-title>
      Fortalece tu <span>contraseña</span>.
    </big-title>

    <!-- NOT ENABLED CARD -->
    <vx-card v-if="showNotEnabledCard">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-5">
            <strong>Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.</strong>
          </p>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button @click="goToAuth2FaPage">Habilitar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END NOT ENABLED CARD -->

    <!-- ENABLED CARD -->
    <vx-card title="Cambio de contraseña" v-if="showChangeCard">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Escribe tu contraseña actual y el código de 6 dígitos generado por el Segundo Factor de Autenticación para continuar:</p>

          <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-2 mb-5" color="danger">
            <span class="font-regular">{{errorMssg}}</span>
          </vs-alert>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Contraseña actual"
                type="password"
                v-validate="'required'"
                v-model.lazy="current_password"
                :danger="hasError('current_password')"
                :danger-text="errorText('current_password')"
                :success="isSuccess('current_password')"
                name="current_password"/>
            </div>
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Código de 6 dígitos"
                type="text"
                maxlength="6"
                v-model.lazy="authCode"
                v-validate="'required|digits:6'"
                :danger="hasError('authCode')"
                :danger-text="errorText('authCode')"
                :success="isSuccess('authCode')"
                name="authCode"/>
            </div>
          </div>

          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button class="mt-2" @click.stop="checkPassword">Continuar</vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>
    <!-- END ENABLED CARD -->

    <!-- CHANGE PASS CARD -->
    <vx-card title="Confirma tu nueva contraseña" v-if="showPasswordFormPrompt">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-3">
            <strong>La contraseña debe contener:</strong>
          </p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <p class="mb-5">
            <feather-icon :svgClasses="`${colorForCriteria(0)} h-4 w-4`" class="p-2" :icon="iconForCriteria(0)" />Una letra minúscula <br>
            <feather-icon :svgClasses="`${colorForCriteria(1)} h-4 w-4`" class="p-2" :icon="iconForCriteria(1)" />Una letra mayúscula <br>
            <feather-icon :svgClasses="`${colorForCriteria(2)} h-4 w-4`" class="p-2" :icon="iconForCriteria(2)" />Un número <br>
          </p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <p class="mb-5">
            <feather-icon :svgClasses="`${colorForCriteria(3)} h-4 w-4`" class="p-2" :icon="iconForCriteria(3)" />Un símbolo <br>
            <feather-icon :svgClasses="`${colorForCriteria(4)} h-4 w-4`" class="p-2" :icon="iconForCriteria(4)" />8 caracteres mínimo <br>
            <feather-icon :svgClasses="`${colorForCriteria(5)} h-4 w-4`" class="p-2" :icon="iconForCriteria(5)" />Sin letras ni números consecutivos <br>
          </p>
        </div>
      </div>

      <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
        <span class="font-regular">{{errorMssg}}</span>
      </vs-alert>

      <div class="vx-row mb-2">
        <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <label class="vs-input--label">Nueva contraseña</label>
          <vx-input-group class="w-full mb-5">
            <vs-input
              v-model.lazy="new_password"
              v-validate="'required'"
              :danger="hasError('new_password')"
              :danger-text="errorText('new_password')"
              :success="passwordFieldValid"
              :type="passInputType"
              name="new_password"/>

            <template slot="append">
              <div class="append-text btn-addon" style="height:40px;">
                <vs-button color="dark" @click="togglePassInputType()" icon-pack="feather" :icon="passInputIcon"></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>

        <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <label class="vs-input--label">Confirma la contraseña</label>
          <vx-input-group class="w-full mb-5">
            <vs-input
              onpaste="return false;"
              v-model.lazy="confirmed_password"
              v-validate="'required'"
              :danger="hasError('confirmed_password')"
              :danger-text="errorText('confirmed_password')"
              :success="confirmPasswordFieldValid"
              :type="confirmPassInputType"
              name="confirmed_password"/>

            <template slot="append">
              <div class="append-text btn-addon" style="height:40px;">
                <vs-button color="dark" @click="toggleConfirmPassInputType()" icon-pack="feather" :icon="confirmPassInputIcon"></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button :disabled="!completePasswordForm || $isLoading" @click="updatePasswordRequest" color="primary" class="mr-6">Enviar</vs-button>
            <vs-button @click="closePasswordFormPrompt" :disabled="$isLoading" type="border" color="primary">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END CHANGE PASS CARD -->

    <!-- COMPLETITION CHANGE NOTICE CARD -->
    <vx-card
      class="mt-5" title="¡Contraseña actualizada!"
      title-color="success" v-if="showCompletedNotice">
      <p class="font-regular">
        Tu nueva contraseña ha sido <strong>guardada</strong> exitosamente.
        Recuerda actualizarla regularmente para mantener tu cuenta segura.
      </p>
      <div class="vx-row mt-6">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button type="border" @click="finishProcess" >Finalizar</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- END COMPLETITION CHANGE NOTICE CARD -->

    <load-error v-if="failed" />
  </div>
</template>

<script>
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
export default {
  data(){
    return {
      isMounted: false,
      authCode: null,
      enabled: false,
      getting: false,
      errorMssg: null,
      completed: false,
      failed: false,

      current_password: null,
      new_password: null,
      confirmed_password: null,
      hasAlias: false,
      changeFormActive: false,
      passInputPassMode: true,
      confirmPassInputPassMode: true,

      userData: {},
      sent: false,
      editEmail: false,
      email_pwd: null,

      criteria: [false, false, false, false, false, false, false],
    }
  },
  async mounted() {
    this.isMounted = false
    this.showLoading(true)
    await this.getUserData();
    this.showLoading(false)
    this.isMounted = true
  },
  computed: {
    showNotEnabledCard(){
      return this.isMounted && !this.enabled;
    },
    showChangeCard(){
      return this.isMounted && this.enabled && !this.changeFormActive && !this.completed;
    },
    showPasswordFormPrompt(){
      return this.isMounted && this.enabled && this.changeFormActive && !this.completed;
    },
    completePasswordForm(){
      return this.new_password != null && this.confirmed_password != null
        && this.new_password.length >= 8 && this.confirmed_password.length >= 8;
    },
    showCompletedNotice(){
      return this.isMounted && this.completed;
    },
    passInputType(){
      return this.passInputPassMode ? "password" : textType;
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
    confirmPassInputType(){
      return this.confirmPassInputPassMode ? "password" : textType;
    },
    confirmPassInputIcon(){
      return this.confirmPassInputPassMode ? iconEye : iconEyeOff;
    },
    passwordFieldValid(){
      return this.testPassword(this.new_password);
    },
    confirmPasswordFieldValid(){
      return this.new_password === this.confirmed_password && this.confirmed_password !=  null;
    },
  },
  methods: {
    async getUserData(){
      try {
        const res = await axios.get(`api/perfil/solar-id/${this.UserId}`);
        this.enabled = res.data.factorEnabled;
      }
      catch (error) {
        this.warn(error);
        this.failed = true
      }
    },
    async goToAuth2FaPage(){
      await this.$router.push({name: 'auth2FaInversionista'});
    },
    async checkPassword(){
      this.errorMssg = null
      this.showLoading(true)
      try {
        const res = await axios.post('/api/auth/user/password/validate', {
          password: this.current_password,
          code: this.authCode
        });

        if(res.data.is_valid){
          this.openChangePasswordModal();
        }
        else {
          this.errorMssg = 'La contraseña ingresada no coincide con la contraseña registrada';
        }
      }
      catch (error) {
        this.verifying = false
        if (typeof error.response.data.message !== 'undefined') {
          this.errorMssg = error.response.data.message
        }else{
          this.errorMssg = 'Ha ocurrido un error. Por favor inténtalo de nuevo.'
        }
      }
      this.showLoading(false)
    },
    openChangePasswordModal() {
      this.new_password = null;
      this.confirmed_password = null;
      this.errorMssg = null
      this.changeFormActive = true;
      this.passInputPassMode = true;
    },
    closePasswordFormPrompt() {
      this.changeFormActive = false;
      this.new_password = null;
      this.errorMssg = null
      this.confirmed_password = null;
      this.passInputPassMode = true;
    },
    async updatePasswordRequest(){
      if(this.new_password !== this.confirmed_password){
        this.errorMssg = "Las contraseñas no coinciden";
      }
      else {
        if(this.testPassword(this.new_password)){
          await this.doUpdatePassword();
        }
        else {
          this.errorMssg = "La contraseña no cumple los requerimientos";
        }
      }
    },
    async doUpdatePassword(){
      this.showLoading(true)
      try {
        let payload = {password: this.new_password};
        this.injectAccountMetadataToPayload(payload);
        await axios.put('/api/auth/user/password', payload);
        this.completed = true;
      }
      catch (error) {
        if (typeof error.response.data.message !== 'undefined') {
          this.errorMssg = error.response.data.message
        }else{
          this.errorMssg = 'Ha ocurrido un error inténtalo de nuevo.'
        }
      }
      this.showLoading(false)
    },
    colorForCriteria(idx){
      return this.criteria[idx] ? 'text-success' : 'text-danger';
    },
    iconForCriteria(idx){
      return this.criteria[idx] ? 'CheckCircleIcon' : 'CircleIcon';
    },
    testPassword(pass){
      if(pass === null || pass === undefined){
        this.criteria[0] = false;
        this.criteria[1] = false;
        this.criteria[2] = false;
        this.criteria[3] = false;
        this.criteria[4] = false;
        this.criteria[5] = false;
        this.criteria[6] = false;
        return false;
      }
      //let pass = this.pw1
      const number = /.*[0-9].*/
      const lower = /.*[a-z].*/
      const upper = /.*[A-Z].*/
      const special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
      const consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
        '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
        'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
        '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
        '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
        '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']

      this.criteria[0] = lower.test(pass);
      this.criteria[1] = upper.test(pass);
      this.criteria[2] = number.test(pass);
      this.criteria[3] = special.test(pass);
      this.criteria[4] = pass.length > 7;
      let no_consecutive = true
      for(let i = 0; i < consecutive.length; i ++){
        if(pass.toLowerCase().includes(consecutive[i])){
          no_consecutive = false
        }
      }
      this.criteria[5] = no_consecutive

      return (this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5]);
    },
    async finishProcess(){
      await this.$router.replace({name: 'seguridadInversionista'});
    },
    togglePassInputType(){
      this.passInputPassMode = !this.passInputPassMode;
    },
    toggleConfirmPassInputType(){
      this.confirmPassInputPassMode = !this.confirmPassInputPassMode;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  }
}
</script>